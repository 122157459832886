<template>
  <div class="container">
    <div class="flex justify-between align-center" style="margin: 8px 0 20px 0; font-size: 18px">
      <el-button @click="handleAdd()" type="primary">添加</el-button>
      <div class="search">
        <el-input v-model="fromData.rule_name" placeholder="搜索规则名" size="small "></el-input>
        <el-input v-model="fromData.contain_cinema_name" placeholder="搜索影院名称" size="small "></el-input>
        <!-- <el-input v-model="fromData.addr" placeholder="搜索地址" size="small "></el-input>
        <el-input v-model="fromData.nm" placeholder="搜索影院" size="small "></el-input>
        <el-select v-model="fromData.is_distribution" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select> -->
        <el-button type="primary" size="small" @click="searchBtn">查询</el-button>
        <el-button type="primary" size="small" @click="clear">重置</el-button>
      </div>
    </div>
    <div class="content">
      <el-table :data="table.tableData" style="width: 100%" border>
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="info">
              <div>包含省份: {{ props.row.contain_province_id }}</div>
              <div>包含城市: {{ props.row.contain_city_id }}</div>
              <!-- <div>适用影院: {{ props.row.contain_cinema_name }}</div> -->
              <div>包含影厅: {{ props.row.contain_cinema_halls }}</div>
              <div>不包含省份: {{ props.row.not_contain_province_id }}</div>
              <div>不包含城市: {{ props.row.not_contain_city_id }}</div>
              <!-- <div>不包含影院名称: {{ props.row.not_contain_cinema_name }}</div> -->
              <!-- <div>不包含影片名称: {{ props.row.not_contain_movie_nm }}</div> -->
              <div>不包含影厅: {{ props.row.not_contain_cinema_halls }}</div>
              <div>创建时间: {{ props.row.created_at }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="rule_name" label="规则名" width=""> </el-table-column>
        <el-table-column prop="sname" label="承包人" width=""> </el-table-column>

        <el-table-column prop="" label="报价区间" width="120">
          <template slot-scope="scope">
            <div>{{ scope.row.min_market_price }}~ {{ scope.row.max_market_price }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="" label="适用影院" width="300">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.show1">{{ scope.row.contain_cinema_name }}</span>
              <span v-if="!scope.row.show1">{{ scope.row.contain_cinema_name_s }}</span>
              <span v-if="scope.row.contain_cinema_name.length > 15" @click="handleOpen(scope.row, 'show1')" :class="[scope.row.show1 ? 'text-grey margin-left-sm' : ' text-blue']">{{
                scope.row.show1 ? '—— 收起' : '...展开'
              }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="" label="包含情侣座">
          <template slot-scope="scope">
            <div>{{ scope.row.lovestatus == 1 ? '是' : '否' }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="contain_cinema_halls" label="是否可调座">
          <template slot-scope="scope">
            <div>{{ scope.row.ischange == 1 ? '是' : '否' }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="contain_cinema_halls" label="包含退单">
          <template slot-scope="scope">
            <div>{{ scope.row.isback == 1 ? '是' : '否' }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="contain_cinema_halls" label="报价方式">
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.offer_type == 1">比例报价</div>
              <!-- <div v-if="scope.row.offer_type == 2">最高价减法报价</div>
              <div v-if="scope.row.offer_type == 3">市场价减法报价</div> -->
              <div v-if="scope.row.offer_type == 4">固定金额报价</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="contain_cinema_halls" label="报价金额">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.offer_price" :key="index">{{ item.contain }}:{{ item.price }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="contain_cinema_halls" label="状态" width="60">
          <template slot-scope="scope">
            <div>{{ scope.row.status == 1 ? '开启' : '关闭' }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="contain_cinema_halls" label="操作" width="260">
          <template slot-scope="scope">
            <div class="flex">
              <el-button @click="handleAdd(scope.row)" size="mini" type="primary">编辑</el-button>
              <el-button @click="handleDel(scope.row)" size="mini" type="danger">删除</el-button>
              <el-button :disabled="scope.$index == 0 ? true : false" type="text" @click="toChange(scope.$index, 'up')">上移</el-button>
              <el-button :disabled="scope.$index + 1 == data.count ? true : false" type="text" @click="toChange(scope.$index, 'down')">下移</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-if="isShow" class="dia dialog">
      <el-dialog :title="title" style="margin-top: 6vh" :visible.sync="dialogVisible" top="0vh" width="930px" :before-close="handleClose">
        <div class="dialog_body">
          <el-form ref="form" :model="form" label-width="100px">
            <div class="flex justify-between">
              <div style="flex: 1">
                <el-form-item label="标题">
                  <el-input placeholder="请输入标题" v-model="form.rule_name"></el-input>
                </el-form-item>
                <el-form-item label="省份:">
                  <el-radio @change="proChange" v-model="form.proRadio" label="1">全部</el-radio>
                  <el-radio @change="proChange" v-model="form.proRadio" label="2">包含省份</el-radio>
                  <el-radio @change="proChange" v-model="form.proRadio" label="3">不包含省份</el-radio>
                </el-form-item>
                <el-form-item label="" v-if="form.proRadio == 2">
                  <el-select class="selectInput" v-model="form.contain_province_id" multiple filterable default-first-option placeholder="请输入包含的省份名称">
                    <el-option v-for="item in form.province" :key="item.id" :label="item.district_name" :value="item.id"> </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" v-if="form.proRadio == 3">
                  <el-select class="selectInput" v-model="form.not_contain_province_id" multiple filterable default-first-option placeholder="请输入不包含的省份名称">
                    <el-option v-for="item in form.province" :key="item.id" :label="item.district_name" :value="item.id"> </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="城市:">
                  <el-radio @change="cityChange" v-model="form.cityRadio" label="1">全部</el-radio>
                  <el-radio @change="cityChange" v-model="form.cityRadio" label="2">包含城市</el-radio>
                  <el-radio @change="cityChange" v-model="form.cityRadio" label="3">不包含城市</el-radio>
                </el-form-item>
                <el-form-item label="" v-if="form.cityRadio == 2">
                  <el-select class="selectInput" @change="selectChange" v-model="form.contain_city_id" multiple filterable placeholder="请输入包含的城市名称">
                    <el-option v-for="item in form.city" :key="item.cid" :label="item.nm" :value="item.id"> </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" v-if="form.cityRadio == 3">
                  <el-select class="selectInput" v-model="form.not_contain_city_id" multiple filterable placeholder="请输入不包含的城市名称">
                    <el-option v-for="item in form.city" :key="item.cid" :label="item.nm" :value="item.id"> </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="适用影院">
                  <div class="flex">
                    <el-input placeholder="请输入适用影院" v-model="form.other"></el-input>
                    <el-button @click="cinemaSearch" style="margin-left: 8px" size="mini" round type="primary">搜索</el-button>
                  </div>
                </el-form-item>

                <el-form-item label="报价模式:">
                  <el-radio @change="changeBL" v-model="form.offer_type" label="1">比例报价</el-radio>
                  <!-- <el-radio v-model="form.offer_type" label="2">最高价减法报价</el-radio>
                  <el-radio v-model="form.offer_type" label="3">市场价减法报价</el-radio> -->
                  <el-radio @change="changeBL" v-model="form.offer_type" label="4">固定金额报价</el-radio>
                </el-form-item>
                <el-form-item label="">
                  <div class="hall" v-if="form.offer_type == 4">
                    <div class="containHall" v-for="(item, index) in form.hallArr" :key="index">
                      <el-input type="number" placeholder="输入金额" v-model="item.price">
                        <template slot="prepend">
                          <el-input class="input" v-model="item.hallName" :disabled="item.disable" placeholder="输入影厅"></el-input>
                        </template>
                      </el-input>
                      <div class="delete" v-if="item.disable == false" @click="deletehall(item, index)">-</div>
                    </div>
                    <span class="plus" @click="addhall">
                      <i class="el-icon-plus"></i>
                    </span>
                    <span style="color: red; margin: 0px">注释*：固定报价模式下，请设置不包含影厅，否则所有影厅都算普通厅！</span>
                  </div>
                  <el-input style="margin-bottom: 8px" type="number" v-model="form.ratioPrice" v-if="form.offer_type == 1" class="input" placeholder="输入比例,不需要输入%"></el-input>
                  <el-input style="margin-bottom: 8px" type="number" v-model="form.highestPrice" v-if="form.offer_type == 2" class="input" placeholder="输入最高价减法报价金额"></el-input>
                  <el-input style="margin-bottom: 8px" type="number" v-model="form.marketPrice" v-if="form.offer_type == 3" class="input" placeholder="输入市场价减法报价金额"></el-input>
                </el-form-item>

                <el-form-item label="包含影厅:" v-if="form.offer_type != 4">
                  <el-select
                    class="selectInput"
                    popper-class="popper-class"
                    v-model="form.contain_cinema_halls"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    placeholder="请输入包含的影厅名称"
                  >
                    <el-option v-for="item in form.empty" :key="item.mid" :label="item.nm" :value="item.nm"> </el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div style="flex: 1">
                <el-form-item label="搜索昵称">
                  <el-input @input="input" clearable="" placeholder="请输入供应商ID获取昵称" v-model="form.suid"></el-input>
                  <div v-if="form.suid_n">{{ form.suid_n }}</div>
                </el-form-item>
                <el-form-item label="不包含影厅:">
                  <el-select
                    class="selectInput"
                    popper-class="popper-class"
                    v-model="form.not_contain_cinema_halls"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    placeholder="请输入不包含的影厅名称"
                  >
                    <el-option v-for="item in form.empty" :key="item.mid" :label="item.nm" :value="item.nm"> </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="市场价">
                  <div class="flex" style="width: 95%">
                    <el-input placeholder="请输入最低市场价" v-model="form.min_market_price"></el-input>
                    <el-input placeholder="请输入最高市场价" style="margin-left: 8px" v-model="form.max_market_price"></el-input>
                  </div>
                </el-form-item>
                <el-form-item label="情侣座">
                  <el-radio v-model="form.lovestatus" label="1">包含</el-radio>
                  <el-radio v-model="form.lovestatus" label="2">不包含</el-radio>
                </el-form-item>
                <el-form-item label="退回订单">
                  <el-radio v-model="form.isback" label="1">包含</el-radio>
                  <el-radio v-model="form.isback" label="2">不包含</el-radio>
                </el-form-item>
                <el-form-item label="接受调座">
                  <el-radio v-model="form.ischange" label="1">含不可调座</el-radio>
                  <el-radio v-model="form.ischange" label="2">只含调座</el-radio>
                </el-form-item>
                <el-form-item label="座位数量">
                  <el-radio v-model="form.seat_num" label="1">全部</el-radio>
                  <el-radio v-model="form.seat_num" label="2">单数</el-radio>
                  <el-radio v-model="form.seat_num" label="3">双数</el-radio>
                </el-form-item>
                <el-form-item label="状态:">
                  <el-select v-model="form.status" placeholder="请选择">
                    <el-option v-for="item in form.options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="sec flex justify-center">
              <el-transfer @change="tranChange" v-model="form.value" :titles="['未选列表', '选中列表']" :data="form.cinema"></el-transfer>
            </div>
            <div style="margin: 20px 20px 0 0" class="flex justify-end">
              <el-button type="primary" @click="onSubmit">确定</el-button>
              <el-button @click="onCancle">取消</el-button>
            </div>
          </el-form>
        </div>
      </el-dialog>
    </div>

    <!-- <div class="page_box">
      <el-pagination @current-change="handleCurrentChange" :current-page="table.currentPage" :page-count="table.totalPages"> </el-pagination>
    </div> -->
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  data() {
    return {
      data: '',
      arr_item: [],
      arr_value: [],
      title: '',
      rule_id: '',
      isShow: false,
      dialogVisible: true,
      form: {
        value: [],
        proRadio: '1',
        cityRadio: '1',
        lovestatus: '1',
        ischange: '1',
        seat_num: '1',
        offer_type: '1',
        status: '1',
        isback: '1',
        province: [],
        city: [],
        empty: [],
        contain_cinema_halls: [],
        cinema: [],
        hallArr: [
          {
            price: '',
            hallName: '普通厅',
            disable: true,
          },
        ],
        options: [
          {
            value: '1',
            label: '开启',
          },
          {
            value: '2',
            label: '关闭',
          },
        ],

        suid: '',
        suid_n: '',
        rule_name: '',
        contain_cinema_ids: '',
        contain_cinema_name: '',
        other: '',
        not_contain_cinema_name: '',
        contain_province_id: [],
        not_contain_province_id: [],
        contain_city_id: [],
        not_contain_city_id: [],
        min_market_price: '',
        max_market_price: '',
        contain_movie_nm: '',
        not_contain_movie_nm: '',
        not_contain_cinema_halls: [],
        contain_cinema_halls: [],
        offer_price: null,
      },

      search: '',
      //   tableData: [

      //   ],
      fromData: {
        // cname: '',
        // rname: '',
        // addr: '',
        // nm: '',
        // is_distribution: '0',
        rule_name: '',
        contain_cinema_name: '',
        page_size: 12,
        page: 1,
        // nopage: 1,
      },
      // 表格数据
      table: {
        tableData: [],
        totalPages: 0,
        currentPage: 1,
      },

      options: [],
      value: [],
    }
  },
  created() {
    this.getPro()
    this.getCity()
    this.getData()
  },
  mounted() {},
  methods: {
    handleOpen(item, type) {
      item[type] = !item[type]
    },
    toChange(index, type) {
      let rule_id = []
      let otherIndex = undefined
      let fakeArr = [...this.table.tableData]
      // let user = JSON.parse(this.$store.state.uInfo.user)
      // let user = this.$store.state.userInfo
      if (type == 'up') {
        otherIndex = Number(index) - 1
      } else if (type == 'down') {
        otherIndex = Number(index) + 1
      }
      let currentItem = this.table.tableData[index]
      let otherItem = this.table.tableData[otherIndex]
      fakeArr.splice(index, 1, otherItem)
      fakeArr.splice(otherIndex, 1, currentItem)
      fakeArr.forEach((item) => {
        rule_id.push(item.id)
      })
      console.log('rule_id.join(', ')', rule_id.join(','))
      let data = {
        // _id: user._id,
        rule_id: rule_id.join(','),
        device: 'pc',
      }
      this.$api.distributionSort(data).then((res) => {
        if (res.data.code == 0) {
          this.table.tableData = fakeArr
        }
      })
    },
    selectChangeP(e) {
      // this.form..map((item,index)=>{
      //   console.log(typeof item)
      //   if(typeof item == 'string'){
      //     this.form.city.map((ite,idx)=>{
      //       if(item == ite.nm){
      //         this.form.contain_city_id[index] = ite.id
      //       }
      //     })
      //   }
      // })
      // console.log(this.form.contain_city_id)
    },
    selectChange(e) {
      this.form.contain_city_id.map((item, index) => {
        console.log(typeof item)
        if (typeof item == 'string') {
          this.form.city.map((ite, idx) => {
            if (item == ite.nm) {
              this.form.contain_city_id[index] = ite.id
            }
          })
        }
      })
      console.log(this.form.contain_city_id)
    },
    changeBL(e) {
      this.form.ratioPrice = ''
      this.form.hallArr = [
        {
          price: '',
          hallName: '普通厅',
          disable: true,
        },
      ]
    },
    handleCurrentChange(e) {
      //   console.log('e', e)
      this.fromData.page = e
      this.getData()
    },
    input() {
      this.sysGetUser()
    },
    proChange(e) {
      switch (e) {
        case '1':
          this.form.contain_province_id = this.form.not_contain_province_id = undefined
          break
        case '2':
          this.form.not_contain_province_id = undefined
          break
        case '3':
          this.form.contain_province_id = undefined
          break
      }
    },
    cityChange(e) {
      switch (e) {
        case '1':
          this.form.contain_city_id = this.form.not_contain_city_id = undefined
          break
        case '2':
          this.form.not_contain_city_id = undefined
          break
        case '3':
          this.form.contain_city_id = undefined
          break
      }
    },
    addhall() {
      this.form.hallArr.push({
        price: '',
        hallName: '',
        disable: false,
      })
    },
    deletehall(item, index) {
      this.form.hallArr.splice(index, 1)
    },
    handleDel(row) {
      this.$api
        .distributionAdd({
          rule_id: row.id,
          is_deleted: 2,
        })
        .then((res) => {
          if (res.data.data) {
            this.$message.success('已删除')
            this.fromData.page = 1
            this.table.tableData = []
            this.getData()
          }
        })
    },
    handleAdd(row) {
      console.log('handleAdd', this.form.province)
      if (row) {
        this.rule_id = row.id
        this.title = '编辑'

        let contain_province_id, not_contain_province_id, contain_city_id, not_contain_city_id, contain_cinema_halls, not_contain_cinema_halls
        if (!row.contain_province_id && !row.not_contain_province_id) {
          this.form.proRadio = '1'
        } else if (row.contain_province_id) {
          this.form.proRadio = '2'
          contain_province_id = row.contain_province_id ? row.contain_province_id.split(',') : []
        } else if (row.not_contain_province_id) {
          this.form.proRadio = '3'
          not_contain_province_id = row.not_contain_province_id ? row.not_contain_province_id.split(',') : []
        }

        if (!row.contain_city_id && !row.not_contain_city_id) {
          this.form.cityRadio = '1'
        } else if (row.contain_city_id) {
          this.form.cityRadio = '2'
          contain_city_id = row.contain_city_id ? row.contain_city_id.split(',') : []
        } else if (row.not_contain_city_id) {
          this.form.cityRadio = '3'
          not_contain_city_id = row.not_contain_city_id ? row.not_contain_city_id.split(',') : []
        }
        contain_cinema_halls = row.contain_cinema_halls ? row.contain_cinema_halls.split(',') : []
        not_contain_cinema_halls = row.not_contain_cinema_halls ? row.not_contain_cinema_halls.split(',') : []

        row.offer_type = String(row.offer_type)
        row.isback = String(row.isback)
        row.ischange = String(row.ischange)
        row.lovestatus = String(row.lovestatus)
        row.seat_num = String(row.seat_num)
        row.status = String(row.status)

        this.form.hallArr = [
          {
            price: '',
            hallName: '普通厅',
            disable: true,
          },
        ]
        console.log('---', row.offer_price)
        console.log('---', this.form.hallArr)
        if (row.offer_price.length == 1) {
          this.form.hallArr[0].price = row.offer_price[0].price
          this.form.hallArr[0].hallName = row.offer_price[0].contain
          if (row.offer_price[0].contain != '普通厅') {
            this.form.ratioPrice = (row.offer_price[0].price * 100).toFixed(0)
          }
        } else {
          row.offer_price.map((item, index) => {
            if (index == 0) {
              this.form.hallArr[0].price = (row.offer_price[0].price * 100).toFixed(0)
              this.form.hallArr[0].hallName = row.offer_price[0].contain
            } else {
              this.form.hallArr.push({
                hallName: item.contain,
                price: item.price,
                disable: false,
              })
            }
          })
        }

        this.form = { ...this.form, ...row, contain_province_id, not_contain_province_id, contain_city_id, not_contain_city_id, contain_cinema_halls, not_contain_cinema_halls }

        this.form.contain_city_id.map((item, index) => {
          if (typeof item == 'string') {
            this.form.city.map((ite, idx) => {
              if (item == ite.nm) {
                this.form.contain_city_id[index] = ite.id
              }
            })
          }
        })
        this.sysGetUser()
        this.getCinema(row.contain_cinema_ids)
        console.log('this.form', this.form)
      } else {
        this.rule_id = ''
        this.form = {
          ...this.form,
          ...{
            value: [],
            proRadio: '1',
            cityRadio: '1',
            lovestatus: '1',
            ischange: '1',
            seat_num: '1',
            offer_type: '1',
            status: '1',
            isback: '1',
            empty: [],
            contain_cinema_halls: [],
            cinema: [],
            hallArr: [
              {
                price: '',
                hallName: '普通厅',
                disable: true,
              },
            ],
            options: [
              {
                value: '1',
                label: '开启',
              },
              {
                value: '2',
                label: '关闭',
              },
            ],

            suid: '',
            suid_n: '',
            rule_name: '',
            contain_cinema_ids: '',
            contain_cinema_name: '',
            other: '',
            not_contain_cinema_name: '',
            contain_province_id: [],
            not_contain_province_id: [],
            contain_city_id: [],
            not_contain_city_id: [],
            min_market_price: '',
            max_market_price: '',
            contain_movie_nm: '',
            not_contain_movie_nm: '',
            not_contain_cinema_halls: [],
            contain_cinema_halls: [],
            offer_price: null,
            ratioPrice: '',
          },
        }
        this.arr_value = []
        this.title = '添加'
      }
      console.log('handleAdd 2', this.form.province)
      this.isShow = true
    },
    onSubmit() {
      if (!this.form.rule_name) {
        this.$message('请填写规则名称')
        return
      }
      if (this.form.value == []) {
        this.$message('请选择影院')
        return
      }
      if (this.form.offer_type != 4 && !this.form.contain_cinema_halls) {
        this.$message('请填写包含影厅')
        return
      }
      if (!this.form.suid) {
        this.$message('请填写供应商id')
        return
      }
      if (!this.form.not_contain_cinema_halls) {
        this.$message('请填写不包含影厅')
        return
      }
      if (!this.form.min_market_price || !this.form.max_market_price) {
        this.$message('请填写市场价')
        return
      }

      let cinema_halls = []
      let offer_price = {}
      let obj = {}
      switch (Number(this.form.offer_type)) {
        case 1:
          if (this.form.contain_cinema_halls.length < 1) {
            obj.contain = '其他'
            obj.price = Number(this.form.ratioPrice) / 100
            offer_price[0] = obj
          } else {
            this.form.contain_cinema_halls.forEach((item, index) => {
              let arrObj = {}
              arrObj.contain = item
              arrObj.price = Number(this.form.ratioPrice) / 100
              offer_price[index] = arrObj
            })
          }
          break
        case 2:
          // obj.contain = '其他'
          // obj.price = this.form.highestPrice
          // offer_price[0] = obj
          if (this.form.contain_cinema_halls.length < 1) {
            obj.contain = '其他'
            obj.price = this.form.highestPrice
            offer_price[0] = obj
          } else {
            this.form.contain_cinema_halls.forEach((item, index) => {
              let arrObj = {}
              arrObj.contain = item
              arrObj.price = this.form.highestPrice
              offer_price[index] = arrObj
            })
          }
          break
        case 3:
          // obj.contain = '其他'
          // obj.price = this.form.marketPrice
          // offer_price[0] = obj
          if (this.form.contain_cinema_halls.length < 1) {
            obj.contain = '其他'
            obj.price = this.form.marketPrice
            offer_price[0] = obj
          } else {
            this.form.contain_cinema_halls.forEach((item, index) => {
              let arrObj = {}
              arrObj.contain = item
              arrObj.price = this.form.marketPrice
              offer_price[index] = arrObj
            })
          }
          break
        case 4:
          let bol = this.form.hallArr.every((item) => {
            if (item.hallName !== '' && item.price !== '') {
              return true
            } else {
              return false
            }
          })
          if (!bol) {
            this.$notify.error({
              title: '失败',
              message: `请输入对应的影厅或报价`,
            })
            return false
          }
          this.form.hallArr.forEach((item, index) => {
            cinema_halls.push(item.hallName)
            let obj = {}
            if (item.hallName == '普通厅') {
              obj.contain = '其他'
            } else {
              obj.contain = item.hallName
            }
            obj.price = Number(item.price)
            offer_price[index] = obj
          })
          break
      }
      offer_price = JSON.stringify(offer_price)

      let contain_cinema_name = '',
        con_arr = []
      this.form.value.map((item, index) => {
        this.form.cinema.map((ite, idx) => {
          if (item == ite.cinemaid) {
            con_arr.push(ite.nm)
          }
        })
      })
      contain_cinema_name = con_arr.join(',')

      let form = {
        ...this.form,
        province: [],
        city: [],
        empty: [],
        contain_cinema_halls: [],
        hallArr: [],
        cinema: [],
        contain_cinema_ids: this.form.value ? this.form.value.join(',') : '',
        contain_province_id: this.form.contain_province_id ? this.form.contain_province_id.join(',') : '',
        not_contain_province_id: this.form.not_contain_province_id ? this.form.not_contain_province_id.join(',') : '',
        contain_city_id: this.form.contain_city_id ? this.form.contain_city_id.join(',') : '',
        not_contain_city_id: this.form.not_contain_city_id ? this.form.not_contain_city_id.join(',') : '',
        not_contain_cinema_halls: this.form.not_contain_cinema_halls ? this.form.not_contain_cinema_halls.join(',') : '',
        contain_cinema_halls: this.form.contain_cinema_halls ? this.form.contain_cinema_halls.join(',') : '',
        contain_cinema_name,
        offer_price: offer_price,
        // 是编辑
        rule_id: this.rule_id ? this.rule_id : '',
      }

      console.log('上传的参数', form)
      this.$api.distributionAdd(form).then((res) => {
        if (res.data.data) {
          this.$message.success('创建成功')
          this.fromData.page = 1
          this.table.tableData = []
          this.isShow = false
          this.getData()
        }
      })
    },
    onCancle() {
      this.isShow = false
    },
    handleClose() {
      this.isShow = false
    },
    clear() {
      this.fromData.rule_name = ''
      this.fromData.contain_cinema_name = ''
      this.fromData.page = 1
      this.table.tableData = []
      this.getData()
    },
    cinemaSearch() {
      this.getCinema()
    },
    searchBtn() {
      this.fromData.page = 1
      this.table.tableData = []
      this.getData()
    },
    handleCurrentChange(e) {
      //   console.log('e', e)
      this.fromData.page = e
      this.getData()
    },
    getData() {
      //   let user = JSON.parse(this.$store.state.uInfo.user)
      //   this.fromData.suid = user._id
      this.$api.distributionList(this.fromData).then((res) => {
        if (res.data.data) {
          res.data.data.rows.map((item, index) => {
            this.$set(res.data.data.rows[index], 'show1', false)
            this.$set(res.data.data.rows[index], 'contain_cinema_name_s', res.data.data.rows[index].contain_cinema_name.slice(0, 15))
          })

          this.data = res.data.data
          this.table.tableData = res.data.data.rows
          console.log(' this.table.tableData', this.table.tableData)
          this.table.totalPages = Math.ceil(res.data.data.count / this.fromData.page_size)
        }
      })
    },
    // 获取省份
    getPro(data = {}) {
      this.$api.getProvince(data).then((res) => {
        if (res.data.code == 0) {
          this.form.province = res.data.data
          console.log('this.form.province', this.form.province)
        }
      })
    },
    //获取城市
    getCity() {
      this.$api.getCity().then((res) => {
        if (res.data.data) {
          this.form.city = res.data.data
          // console.log('this.form.city ', this.form.city)
        }
      })
    },
    tranChange() {
      this.arr_item = []
      if (this.form.value != []) {
        this.form.value.map((item, index) => {
          this.form.cinema.map((ite, idx) => {
            if (item == ite.cinemaid) {
              this.arr_item.push(ite)
            }
          })
        })
      }
      this.arr_value = this.form.value
      console.log('tranChange---', this.form.value)
    },
    //获取城市
    getCinema(ids) {
      // if(this.form.contain_city_id != []){

      // }
      this.$api
        .sysGetCinema({
          nm: this.form.other,
          cid: this.form.contain_city_id,
        })
        .then((res) => {
          if (res.data.data) {
            res.data.data.map((item, index) => {
              item.key = item.cinemaid
              item.label = item.nm
            })
            this.form.cinema = res.data.data
            if (ids) {
              this.form.value = ids.split(',')
            }
            if (this.arr_value != []) {
              this.arr_item.map((item, index) => {
                item.key = item.cinemaid
                item.label = item.nm
              })
              let is_has = false
              this.arr_item.map((item, index) => {
                this.form.cinema.map((ite, idx) => {
                  if (item.cinemaid == ite.cinemaid) {
                    is_has = true
                  }
                })
                if (!is_has) {
                  this.form.cinema.push(item)
                }
                is_has = false
              })
              this.arr_value.map((item, index) => {
                if (this.form.value.indexOf(item) == -1) {
                  this.form.value.push(item)
                }
              })
            }
          }
        })
    },
    //获取城市
    sysGetUser() {
      this.form.suid_n = ''
      this.$api
        .sysGetUser({
          id: this.form.suid,
          id_class: 1,
        })
        .then((res) => {
          if (res.data.data) {
            // res.data.data.map((item,index)=>{
            //   item.key = item.cinemaid
            //   item.label = item.nm
            // })
            this.form.user = res.data.data
            if (this.form.user.length == 1) {
              this.form.suid_n = `${this.form.user[0].nickname}(${this.form.user[0].id})`
            }
            console.log(this.form.user)
          }
        })
    },
  },
}
</script>

<style scoped lang="less">
.container {
  padding: 20px;
  margin: 0 20px 20px 20px;
  background: #fff;
  border-radius: 8px;
}
.search {
  display: flex;
  justify-content: flex-start;
  .el-input,
  .el-select {
    margin-right: 20px;
  }

  /deep/ .el-input__inner {
    width: 200px;
    height: 32px;
  }
  /deep/ .el-input__icon {
    line-height: 32px;
  }
  //   .el-button--primary {
  //     margin-left: 225px;
  //   }
}

.dialog {
  /deep/ .el-transfer-panel {
    width: 300px !important;
  }
  /deep/.dialog_body {
    .el-radio__input.is-checked .el-radio__inner {
      border-color: #185ae9;
      background: #185ae9;
    }
    .el-radio__input.is-checked + .el-radio__label {
      color: #185ae9;
    }
    .el-form-item {
      margin-bottom: 5px !important;
    }
    .aboutMovie {
      .el-form-item__content {
        margin-left: 120px !important;
      }
    }
    .price {
      .el-input {
        width: 30% !important;
      }
      .el-form-item__content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
    .el-input {
      max-width: 95% !important;
      .el-input__inner {
        height: 34px;
      }
    }
    .selectInput {
      width: 100%;
      .el-input__suffix {
        display: none;
      }
      .el-select-dropdown {
        display: none;
      }
    }
    .hall {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      .containHall {
        position: relative;
        margin-right: 20px;
        max-width: 280px;
        margin-bottom: 8px;

        .delete {
          width: 10px;
          height: 10px;
          text-align: center;
          line-height: 10px;
          position: absolute;
          border: 1px solid #bababa;
          background: #ffffff;
          color: #bababa;
          border-radius: 50%;
          top: -4px;
          right: -4px;
          cursor: pointer;
        }
        .el-input-group--prepend {
          border: 1px solid #d1d1d1;
          border-radius: 4px;
          overflow: hidden;
        }
        .el-input__inner {
          width: 100px;
          border: none;
        }
        .el-input-group__prepend {
          padding: 0 !important;
          border: none !important;
        }
        .input {
          .el-input__inner {
            border-right: 1px solid #d1d1d1;
          }
        }
        .el-input-group {
          vertical-align: baseline;
        }
      }
      .plus {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background: #185ae9;
        border-radius: 4px;
        margin-bottom: 8px;
      }
    }
    .button {
      margin-top: 30px;
      margin-bottom: 30px;
      .el-form-item__content {
        margin-left: 0px !important;
        display: flex;
        justify-content: center;
      }
    }
  }
  /deep/ .el-dialog__header {
    background: #4295fb;
  }
  /deep/ .el-dialog__title {
    color: #fff !important;
  }
  /deep/.el-dialog__headerbtn .el-dialog__close {
    color: #fff;
  }
  /deep/ .el-input__inner {
    // width: 200px;
    height: 32px;
  }
  .el-select {
    width: 100%;
  }
}
.info {
  div {
    padding: 4px 0;
  }
}
.text-grey{
  color: #bababa;
}
.text-blue{
  color: #4295fb;
}
.margin-left-sm{
  margin-left: 10px;
}
</style>
